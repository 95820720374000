<template>
  <div class="valentines-page">
    <h1 class="headline" style="margin-top: 20px;">
      <span class="thin-text">Shop our Valentine's Day</span><br >
      <span class="bold-text">Gift Finder</span><br><br>

      <span class="bold-text">For Her</span>
    </h1>


    <ForHer></ForHer>
  </div>
</template>

<script>
import axios from "axios";
import BreadCrumb from "../../components/Navs/BreadCrumb.vue";
import ShopCategoryCard from "../../components/Cards/ShopCategoryCard.vue";
import ProductCard from "../../components/Cards/ProductCard.vue";
import ListProductCard from "../../components/Cards/ListProductCard.vue";
import ForHer from "../../views/pages/Home/ForHer.vue";
import { mapState } from "vuex";
export default {
  components: { BreadCrumb, ShopCategoryCard, ListProductCard, ProductCard,ForHer },
  data() {
    return {
      selectedCategory: "All",
      products: [],
      shopCategory: {},
      links: {},
      meta: {},
      url: null,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1,
      selectedCategories: [],
      isLoading: true,
      fullPage: false,
      show: false,
      categoryFilters: [],
      catFilterString: "",
    };
  },
  computed: {
    ...mapState({
      shop: (state) => state.shop,
    }),
  },
  mounted() {
    this.updateCategoryFilters("");
    //this.getProductsV2();
  },
  methods: {
    updateCategoryFilters(cat_id) {
      var cat_index = this.categoryFilters.indexOf(cat_id);
      if (cat_index > -1) {
        //It exists in the array
        //Remove it from the array
        this.categoryFilters.splice(cat_index, 1);
      } else if (cat_id) {
        // It doesnt exist in the array
        // Adde the item to the array
        this.categoryFilters.push(cat_id);
      }

      // Update filters string
      this.catFilterString = "";
      //alert(this.categoryFilters.length);
      if (this.categoryFilters.length === 0) {
        //Add All Categories if nothing is selected
        this.shop.categories.forEach((item, index) => {
          this.catFilterString = this.catFilterString + item.id;
          if (index !== this.categoryFilters.length - 1) {
            this.catFilterString = this.catFilterString + "|";
          }
        });
      } else {
        //If we have at least 1 selected
        this.categoryFilters.forEach((item, index) => {
          this.catFilterString = this.catFilterString + item;
          if (index !== this.categoryFilters.length - 1) {
            this.catFilterString = this.catFilterString + "|";
          }
        });
      }

      // Get the Products
      this.getProductsV2();
    },

    addCategoryFilter(categoryId) {
      if (this.selectedCategories.indexOf(categoryId) >= 0) {
        this.selectedCategories = this.selectedCategories.filter(
          (catId) => categoryId !== catId
        );
      } else {
        this.selectedCategories.push(categoryId);
      }
      this.getProducts();
    },

    selectCategory(name) {
      this.selectedCategory = name;
    },

    getProducts() {
      this.$loading(false);
      let queryParams = [];
      if (this.selectedCategories.length > 0) {
        queryParams.push(
          "shops/categories=" + this.selectedCategories.join(",")
        );
      }
      let url = "products";
      if (this.url) {
        url = this.url;
      }

      if (queryParams.length > 0) {
        if (url.indexOf("?") < 0) {
          url = url + "?" + queryParams.join("&");
        } else {
          url = url + "&" + queryParams.join("&");
        }
      }
      this.isLoading = true;
      axios
        .get(url)
        .then((response) => {
          this.products = response.data.data;
          this.links = response.data.links;
          this.meta = response.data.meta;
          this.count = response.data.meta.total;
          this.pageNumber = response.data.meta.current_page;
          this.handleNextPage(response.data.links);
          this.handlePrevPage(response.data.links);
          this.isLoading = false;
          this.$loading(false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$loading(false);
        });
    },

    getProductsV2() {
      this.$loading(false);
      this.isLoading = true;
      axios
        .get("products?category=" + this.catFilterString)
        .then((response) => {
          this.products = response.data.data;
          this.links = response.data.links;
          this.meta = response.data.meta;
          this.count = response.data.meta.total;
          this.pageNumber = response.data.meta.current_page;
          this.handleNextPage(response.data.links);
          this.handlePrevPage(response.data.links);
          this.isLoading = false;
          this.$loading(false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$loading(false);
        });
    },

    addToCart(product, prop) {
      let newProp = {
        // color: prop.color,
        // size: prop.size,
        qty: 1,
      };
      if (product.properties.hasOwnProperty("color")) {
        newProp["color"] = product.properties.color;
      }
      if (product.properties.hasOwnProperty("size")) {
        newProp["size"] = product.properties.size;
      }
      this.add(product, newProp);
    },
    scrollTop() {
      let element = document.getElementsByClassName("ps-shopping-product");
      if (element.length > 0) {
        const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
    },
    goToNextPage: function() {
      this.url = this.nextPage;
      if (this.url) {
        this.getProducts()
      }
      this.scrollTop();
    },

    goToPrevPage: function() {
      this.url = this.prevPage;
      if (this.url) {
        this.getProducts()
      }
      this.scrollTop();
    },

    handleNextPage(links) {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false;
      }
    },

    handlePrevPage(links) {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false;
      }
    },
  },
};
</script>

<style scoped>
.valentines-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.thin-text {
  font-weight: 300;
}

.bold-text {
  font-weight: bold;
}
  
.card-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.card {
  width: 200px;
  height: 200px;
  margin: 0 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.full-card {
  width: 96%;
  height: 42%;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.full-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>